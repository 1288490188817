<template>
  <div class="bg">
    <h5>ABN Details</h5>
    <div class="row row-cols-xl-3 row-cols-md-2 row-cols-1">
      <info-line label="Registered name" :value="abn.name" class="col" />
      <info-line label="ABN status" :value="abn.status" class="col" />
      <info-line label="Registered for GST" class="col">
        {{ abn.gst | yesNo }}
      </info-line>
      <info-line label="DGR endorsed" class="col">
        {{ abn.dgrEndorsed | yesNo }}
      </info-line>
      <info-line label="Charity type" class="col">
        {{ abn.atoCharityType | emptyFill }}
      </info-line>
      <info-line label="ACNC registration" class="col">
        {{ abn.acncRegistration | emptyFill }}
      </info-line>
      <info-line label="Location" class="col">
        {{ abn.state }}, {{ abn.postcode }}
      </info-line>
      <info-line label="Tax concessions" class="col">
        <ul
          class="clean"
          v-if="abn.taxConcessions && abn.taxConcessions.length > 0"
        >
          <li
            class=""
            v-for="concession in abn.taxConcessions"
            :key="concession"
          >
            {{ concession }}
          </li>
        </ul>
        <div v-else>-</div>
      </info-line>
    </div>
  </div>
</template>

<script>
import InfoLine from "../interface/InfoLine";
export default {
  name: "AbnDetails",
  props: ["abn"],
  components: {InfoLine},
  filters: {
    yesNo: value => (value ? "Yes" : "No"),
    emptyFill: value => (value ? value : "-"),
  },
};
</script>

<style lang="scss" scoped>
@import "../../../content/scss/vendor";
.bg {
  border: 1px solid $border-color;
  border-radius: $border-radius;
  background-color: $input-disabled-bg;
  padding: 1em 1em 0;
}
h5 {
  line-height: 1;
  margin-bottom: 0.5em;
}
ul.clean {
  list-style: none;
  padding: 0;
}
</style>
