<template>
  <div class="bg">
    <h5>NZBN Details</h5>
    <div class="row row-cols-xl-3 row-cols-md-2 row-cols-1">
      <info-line label="Registered Name" :value="nzbn.name" class="col" />
      <info-line
        label="Registration Date"
        :value="nzbn.registrationDate"
        class="col"
      />
      <info-line label="Entity Status" :value="nzbn.entityStatus" class="col" />
      <info-line label="Entity Type" :value="nzbn.entityType" class="col" />
      <info-line
        label="Registered Address"
        v-if="nzbn.registeredAddress"
        class="col"
      >
        {{ this.singleLineAddress(nzbn.registeredAddress) | emptyFill }}
      </info-line>
      <info-line label="Registered Address" v-else class="col">
        {{ nzbn.registeredAddress | emptyFill }}
      </info-line>
      <info-line label="Office Address" v-if="nzbn.officeAddress" class="col">
        {{ this.singleLineAddress(nzbn.officeAddress) | emptyFill }}
      </info-line>
      <info-line label="Office Address" v-else class="col">
        {{ nzbn.officeAddress | emptyFill }}
      </info-line>
    </div>
  </div>
</template>

<script>
import InfoLine from "../interface/InfoLine";
import {dataMapper} from "@/utils/dataMapper";
export default {
  name: "NzbnDetails",
  props: ["nzbn"],
  components: {InfoLine},
  filters: {
    yesNo: value => (value ? "Yes" : "No"),
    emptyFill: value => (value ? value : "-"),
  },
  methods: {
    singleLineAddress(address) {
      return dataMapper.singleLineAddress(address);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../content/scss/vendor";
.bg {
  border: 1px solid $border-color;
  border-radius: $border-radius;
  background-color: $input-disabled-bg;
  padding: 1em 1em 0;
}
h5 {
  line-height: 1;
  margin-bottom: 0.5em;
}
ul.clean {
  list-style: none;
  padding: 0;
}
</style>
